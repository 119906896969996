import { CreateStructuralRouteFn, StructuralRoute } from '@core/models/route.models';

const createStructuralRoute: CreateStructuralRouteFn = (
  prefix: string | null = '',
  suffix: string = '',
  child?: StructuralRoute,
  paramName: string = 'id'
): StructuralRoute => {
  return {
    prefix,
    suffix,

    get route() {
      const prefix: string = `${this.prefix ? `${this.prefix}/` : ''}`;
      const suffix: string = `${this.suffix ? `/${this.suffix}` : ''}`;
      const _route: string = `${prefix}:${paramName}${suffix}`;
      return child ? `${_route}/${child.route}` : _route;
    },

    construct(param: string, prefixUrl: string = '') {
      const _route: string = `${this.prefix ? `${this.prefix}/` : ''}${param}${this.suffix ? `/${this.suffix}` : ''}`;
      const currentUrl: string = `${prefixUrl ? `${prefixUrl}/` : ''}${_route}`;
      return child ? (_param: string) => child.construct(_param, currentUrl) : currentUrl;
    },
  };
};

/**
 *  temporary
 *
 *  @see AuthGuard.canMatchAuthorized
 *  @see AuthRoutingModule
 */
export const NOT_FOUND_RC = {
  root: 'not-found',
};

export const INTAKE_SURVEY_RC = {
  root: 'intake-form',
};

export const TEMP_REGISTRATION_RC = {
  root: 'registration',
  children: {
    completed: 'completed',
    closed: 'closed',
    canceled: 'canceled',
  },
};

export const SESSIONS_RC = {
  root: 'sessions',
  children: {
    list: {
      root: '',
      children: {
        admin: '',
        openedRegistration: 'opened-registration',
        mySessions: 'my-sessions',
        waitlist: 'waitlist',
        declined: 'declined',
        passed: 'passed',
      },
    },
    create: 'create',
    edit: createStructuralRoute(null, 'edit'),
    registration: createStructuralRoute(null, 'registration'),
    yearlyRegistration: 'yearly-registration',
    sessionDetails: createStructuralRoute(null, 'details'),
  },
};
export const FAMILIES_RC = {
  root: 'families',
  children: {
    list: '',
    create: 'create',
    details: createStructuralRoute(null, 'details'),
    own: 'own',
  },
};

export const PROFILE_RC = {
  root: 'profile',
};

export const AUTHORIZED_RC = {
  root: '',
  children: {
    sessions: SESSIONS_RC,
    families: FAMILIES_RC,
    profile: PROFILE_RC,
  },
  intakeSurvey: INTAKE_SURVEY_RC,
};

export const AUTH_RC = {
  root: 'auth',
  children: {
    logIn: 'log-in',
    signUp: 'sign-up',
    forgotPassword: 'forgot-password',
    resetPassword: 'reset-password',
    createPassword: 'create-password',
  },
  tempRegistration: TEMP_REGISTRATION_RC,
};

export const TERMS_AND_CONDITION_RC = {
  root: 'terms-and-condition',
};
